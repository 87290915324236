import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import color from 'color';
import theme from 'helpers/theme';
import PartyBadge from 'components/party-badge';
import { BoxHeading } from 'components/headings';
import InfoBox from 'components/infobox';
import { Tooltip } from 'react-tippy';
import { Icon } from 'components/icons';

const Container = styled.div`
  grid-area: main;
`;

const RepresentativeRole = styled.p`
  color: ${color(theme.colors.secondaryText)};
  font-size: ${theme.fontSizes.medium};
  font-weight: 400;
  margin: 0;
  line-height: 1.4em;

  @media screen and {max-width: ${theme.mobileBreakpoint}px} {
    margin-left: ${theme.spacing.gap};
    font-size: ${theme.fontSizes.base};
  }
`;

const PartyBadgeContainer = styled.div`
  @media screen and {max-width: ${theme.mobileBreakpoint}px} {
    margin-left: ${theme.spacing.gap};
  }
`;

const PolicyList = styled.ul`
  padding-left: 1.5rem;
`;

const Policy = styled.li`
  line-height: 1.5em;
`;

const RepresentativeInfo = ({ representative, electorateName, party }) => {
  const [policy, setPolicy] = useState();

  useEffect(() => {
    const policies = representative.policies.splice(0, 4); // Slice limits this list to only 4 policies.
    setPolicy(policies);
  }, [representative]);

  const offices = representative.offices.map((office) => {
    return (
      <RepresentativeRole key={office}>
        {office}
      </RepresentativeRole>
    );
  });

  const policies = policy?.map((policy) => {
    return <Policy key={policy.name}>{policy.name}</Policy>;
  });

  const PolicyPosition = () => (
    <>
      <BoxHeading>
        Policy Positions
        <span style={{
          fontSize: '0.8em',
          float: 'right',
        }}>
          <Tooltip
            title="These policies are derived from recent vote patterns."
            position="top-center">
            <Icon type='info' size='23' color='#777' />
          </Tooltip>
        </span>
      </BoxHeading>
      <PolicyList>{policies}</PolicyList>
    </>
  )

  return (
    <Container>

      <RepresentativeRole>
        Representative for {electorateName}
      </RepresentativeRole>

      {offices}

      <PartyBadgeContainer>
        <PartyBadge color={party?.color ?? '#fff'}>{party?.shortName ?? ''}</PartyBadge>
      </PartyBadgeContainer>
      {/*<InfoBox padded>*/}
      {/*  <PolicyPosition />*/}
      {/*</InfoBox>*/}
    </Container>
  )
}

export default RepresentativeInfo;
